import { graphql } from "gatsby"
import React from "react"
import Album from "./albums/Album"
import BlockContentPrimary from "./block content/blockContentPrimary"

// setion data contains block content, and mediacontent
const PageSection = ({ sectionData = null, rawAlbumData = null }) => {
  const { _rawMedia, _rawRichTextContent } = sectionData

  return (
    <>
      <BlockContentPrimary blockData={_rawRichTextContent} />
      {_rawMedia && (
        <div className="w-10/12 mt-8 justify-self-center">
          <Album rawImageData={_rawMedia} thumbnailPosition="bottom" />
        </div>
      )}
    </>
  )
}

export default PageSection
