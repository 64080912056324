import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import { Link } from "gatsby"

import HeroContainer from "../components/heroContainer"
import MainCard from "../components/card/MainCard"

import BlockContentPrimary from "../components/block content/blockContentPrimary"
import PageSection from "../components/PageSection"
import PageTitle from "../components/PageTitle"
import ButtonLink from "../components/ButtonLink"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import { sanityConfig } from "../sanityConfig"
import StandardContainer from "../components/containers/StandardContainer"

const EmphText = ({ text = "TEXT HERE" }) => (
  <p className="text-4xl font-heading text-center my-8">{text}</p>
)

const Lifestyle = ({ data }) => {
  const { lifestylePageContent } = data
  const { communitySection } = lifestylePageContent
  const defaultHeroFluid = data.heroImage.childImageSharp.fluid
  const secondFluid = data.secondBannerImage.childImageSharp.fluid

  const fluidBanner =
    getFluidGatsbyImage(
      lifestylePageContent?.rawHeaderImage?.asset?.id,
      { maxWidth: 1200 },
      sanityConfig
    ) || defaultHeroFluid

  const imageCards = [
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
  ]
  const heroProps = {
    fluid: fluidBanner,
    gradient: true,
  }

  const imageCardProps = {
    fluid: secondFluid,
    cardGridClass: "grid grid-cols-3 mr-thirty w-ninety font-semibold",
    headerText: "Communities",
    headerClass: "mt-twen",
    cards: imageCards,
    height: "1/2",
    dividerColor: "fifth",
  }

  const sections = [
    { name: "Communities", id: "communities" },
    { name: "Amenities", id: "amenities" },
    { name: "Employment", id: "employment" },
    { name: "Housing", id: "housing" },
    { name: "Services", id: "services" },
    { name: "Families", id: "families" },
  ]

  return (
    <Layout>
      <SEO title="Lifestyle" />
      <HeroContainer props={heroProps} />
      {/* <ImageCardContainer 
        props={imageCardProps}
      /> */}
      <StandardContainer className="py-10 px-2 md:px-5 relative text-primary">
        <PageTitle text="Lifestyle" />

        <div className="flex justify-center flex-wrap justify-self-center text-primary z-20 gap-y-1 w-11/12 mt-2">
          {sections.map((section) => (
            <Link
              to={`#${section.id}`}
              className="text-sm sm:text-md lg:text-lg px-2 mt-2 mx-1 md:px-4 py-1 md:py-2 text-quaternary  uppercase    hover:text-secondary rounded-lg text-center underline "
            >
              {section.name}
            </Link>
          ))}
        </div>
        <section className="  ">
          <BlockContentPrimary blockData={lifestylePageContent.rawIntro} />
        </section>
        <div>
          <MainCard
            id="communities"
            className="text-secondary "
            title={{ text: "Communities" }}
            headerTop="top-56"
          >
            {/* <BlockContentPrimary
              blockData={communitySection._rawRichTextContent}
            /> */}
            <PageSection sectionData={lifestylePageContent.communitySection} />
          </MainCard>
          <MainCard
            id="amenities"
            className="text-secondary pt-16  "
            title={{ text: "Amenities" }}
            headerTop="top-52"
          >
            <PageSection sectionData={lifestylePageContent.amenitiesSection} />
          </MainCard>
          <MainCard
            id="employment"
            className="text-secondary pt-16 "
            title={{ text: "Employment" }}
            headerTop="top-52"
          >
            <PageSection sectionData={lifestylePageContent.employmentSection} />
          </MainCard>
          <MainCard
            id="housing"
            className="text-secondary pt-16  "
            title={{ text: "Housing" }}
            headerTop="top-40"
          >
            <PageSection sectionData={lifestylePageContent.housingSection} />
          </MainCard>
          <MainCard
            id="services"
            className="text-secondary pt-16  "
            title={{ text: "Services" }}
            headerTop="top-40"
          >
            <div className="grid">
              <p>
                Sioux Lookout offers many conveniences. Please visit the{" "}
                <Link
                  to="/lifestyle/services"
                  className="underline font-bold hover:text-fifth"
                >
                  Services Page
                </Link>{" "}
                for a full list of services and their contact information.
              </p>
              <ButtonLink to="/lifestyle/services" className="justify-self-end">
                Services Page
              </ButtonLink>
            </div>
            {/* <PageSection sectionData={lifestylePageContent.servicesSection} /> */}
          </MainCard>
          <MainCard
            id="families"
            className="text-secondary pt-16  "
            title={{ text: "Families" }}
            headerTop="top-36"
          >
            <PageSection sectionData={lifestylePageContent.familiesSection} />
          </MainCard>
        </div>
      </StandardContainer>
    </Layout>
  )
}
export default Lifestyle

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "lifestyle_hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondBannerImage: file(relativePath: { eq: "second_banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lifestylePageContent: sanityLifestylePage {
      rawHeaderImage: _rawHeaderImage(resolveReferences: { maxDepth: 10 })
      rawIntro: _rawIntro(resolveReferences: { maxDepth: 10 })
      communitySection {
        _rawRichTextContent(resolveReferences: { maxDepth: 10 })
        _rawMedia(resolveReferences: { maxDepth: 10 })
      }
      amenitiesSection {
        _rawRichTextContent(resolveReferences: { maxDepth: 10 })
        _rawMedia(resolveReferences: { maxDepth: 10 })
      }
      employmentSection {
        _rawRichTextContent(resolveReferences: { maxDepth: 10 })
        _rawMedia(resolveReferences: { maxDepth: 10 })
      }
      familiesSection {
        _rawRichTextContent(resolveReferences: { maxDepth: 10 })
        _rawMedia(resolveReferences: { maxDepth: 10 })
      }
      housingSection {
        _rawRichTextContent(resolveReferences: { maxDepth: 10 })
        _rawMedia(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`
